import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import Vant from 'vant';
import 'vant/lib/index.css';
import '@/utils/rem.js'
Vue.config.productionTip = false
Vue.prototype.$request = axios
Vue.use(Vant)
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
