// 引入依赖
import Vue from 'vue'
import VueRouter from 'vue-router'
// 使用路由中间件
Vue.use(VueRouter)
// 路由表
const routes = [{
        path: '/',
        name: 'home',
        component: () => import('../view/home.vue'),
        hidden:true
    },
    {
        path: '/business',
        name: 'business',
        component: () => import('../view/business.vue')
    },{
        path: '/news',
        name: 'news',
        component: () => import('../view/news.vue')
    },{
        path: '/recruitment',
        name: 'recruitment',
        component: () => import('../view/recruitment.vue')
    },{
        path: '/aboutservice',
        name: 'aboutservice',
        component: () => import('../view/aboutservice.vue')
    },{
        path: '/aboutus',
        name: 'aboutus',
        component: () => import('../view/aboutus.vue')
    },{
        path: '/contactus',
        name: 'contactus',
        component: () => import('../view/contactus.vue')
    },{
        path: '/detailsone',
        name: 'detailsone',
        component: () => import('../view/detailsone.vue')
    },{
        path: '/detailstwo',
        name: 'detailstwo',
        component: () => import('../view/detailstwo.vue')
    },{
        path: '/detailsthree',
        name: 'detailsthree',
        component: () => import('../view/detailsthree.vue')
    },{
        path: '/detailsfour',
        name: 'detailsfour',
        component: () => import('../view/detailsfour.vue')
    },{
        path: '/detailsfive',
        name: 'detailsfive',
        component: () => import('../view/detailsfive.vue')
    },{
        path: '/detailssix',
        name: 'detailssix',
        component: () => import('../view/detailssix.vue')
    },{
        path: '/detailsseven',
        name: 'detailsseven',
        component: () => import('../view/detailsseven.vue')
    },{
        path: '/detailseight',
        name: 'detailseight',
        component: () => import('../view/detailseight.vue')
    },{
        path: '/miniprogrom',
        name: 'miniprogrom',
        component: () => import('../view/miniprogrom.vue')
    },{
        path: '/aboutnews',
        name: 'aboutnews',
        component: () => import('../view/aboutnews.vue')
    }
]
// 修改路由模式
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

//暴露
export default router