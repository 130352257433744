<template>
  <div>
    <router-view></router-view>
  </div>
</template>
<script>

export default {
  created () {
    document.title = '新海丰(青岛)跨境电商服务有限公司'
  }
};
</script>
<style>
</style>
